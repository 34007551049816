import styled, { css } from 'styled-components';
import Page from '../components/Page'
import AboutMe from '../components/AboutMe';
import { fromDesktop, fromMedium, fromTablet } from '../styles/mediaQueries';
import { grey, light, primary, primaryDark, primarySubdued, secondaryDark } from '../styles/colors';
import { body } from '../styles/fonts';
import { Link, NavLink } from 'react-router-dom';
import portfolioThumbnail from '../images/homepage-covers/illustration-cover.jpg';
import shopThumbnail from '../images/homepage-covers/shop-cover.jpeg';
import publicationsThumbnail from '../images/homepage-covers/publications-cover.jpeg';
import conventions from '../data/conventions';
import services from '../data/services';
import { DNoise } from '../components/ui/ImageNoise';

const PageTitle = styled.h1`
  text-align: center;
  margin-top: 1rem;
  font-family: ${body};
  text-transform: uppercase;
  font-weight: 800;
  font-size: 2.2rem;

  span {
    display: block;
    text-transform: none;
    font-weight: 400;
    font-size: 0.8em;
    color: ${grey};
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 1rem 1rem;
  grid-gap: 2rem;
  max-width: 950px;
  
  ${fromMedium} {
    grid-template-columns: 1fr 1fr;
  }
`

export const ImageContainer = styled.div`
  overflow: hidden;
`

const sectionLinkStyles = css`  display: flex;
flex-direction: column;
position: relative;
width: 100%;
z-index: 10;
/* overflow: hidden; */
height: 12rem;

&::before {
  display: block;
  width: 100%;
  height: 100%;
  content: ' ';
  background-color: ${secondaryDark};
  transform: rotate(2deg);
  position: absolute;
  top: 0;
  left: 0;
}

${fromTablet} {
  height: 20rem;
}

img {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 100%;
  transition: all 0.2s ease;
  filter: saturate(100%);
}

h2 {
  position: absolute;
  font-family: ${body};
  text-transform: uppercase;
  font-weight: 800;
  color: ${light};
  margin: 0;
  padding: 0.5em;
  bottom: 0;
  left: 0;
  background-color: rgba(21, 5, 120, 0.7);
  transition: all 0.3s ease;
  width: 100%;
  text-align: center;

  font-size: 2.5em;

  ${fromMedium} {
    font-size: 2em;
  }

  ${fromDesktop} {
    font-size: 2em;
  }
}

&:hover, &:focus {
  h2 {
    background-color: ${primaryDark};
  }

  img, &::after {
    filter: saturate(120%);
    transform: scale(1.1);
  }
}`;

const ExternalSectionLink = styled.a`
  ${sectionLinkStyles}
`;

const SectionLink = styled(Link)`
  ${sectionLinkStyles}
`;

const InfoSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.3rem;
  margin-top: 2.5rem;

  ${fromMedium} {
    /* text-align: left; */
    font-size: 1.2rem;
    margin: 0;
  }
`;

const InfoSectionTitle = styled.h3`
  font-size: 1.5em;
  margin: 0 0 1em;
  font-weight: 400;
`

const InfoSectionList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  font-weight: 500;
  
  li {
    line-height: 1.3em;
  }
`

const NextShows = styled.section`
  background-color: ${primarySubdued};
  font-size: 1.5rem;
  padding: 3rem 1rem 1.5rem;
  margin: 3rem 0 0;
  width: 100vw;
  text-align: center;
  position: relative;
  display: block;

  ul {
    list-style: none;
    padding: 0;
    font-size: 0.8em;
  }

  li {
    margin-bottom: 1.5rem;
  }

  li span {
    display: block;
    opacity: 0.8;
  }

  &::before {
    display: block;
    width: 100%;
    height: 100%;
    content: ' ';
    background-color: ${primary};
    transform: rotate(-1.5deg);
    position: absolute;
    top: -1rem;
    left: 0;
    z-index: -1;
  }
`;

const InfoLink = styled.li`
  a {
    font-family: ${body};
    display: block;
    margin-top: 0.5em;
    font-weight: 600;
    line-height: 1.5em;
    
    &::after {
      display: inline-block;
      content: '>';
      padding-left: 0.5em;
    }
  }
`;

function Home () {
  return (
    <Page>  
      <PageTitle>
        Claudia Matosa
        <span>Illustrator · Cartoonist</span>
      </PageTitle>

    <Grid>
      <SectionLink to={`/portfolio`}>
        <ImageContainer><DNoise><img src={portfolioThumbnail} alt="Portfolio" /></DNoise></ImageContainer>
        <h2>Portfolio</h2>
      </SectionLink>

      <SectionLink to={`/publications`}>
        <ImageContainer><DNoise><img src={publicationsThumbnail} alt="Publications" /></DNoise></ImageContainer>
        <h2>Books</h2>
      </SectionLink>

      <ExternalSectionLink href="https://shop.claudiamatosa.com" target="_blank">
        <ImageContainer><DNoise><img src={shopThumbnail} alt="Shop" /></DNoise></ImageContainer>
        <h2>Shop</h2>
      </ExternalSectionLink>

      <InfoSection>
        <InfoSectionTitle>Services</InfoSectionTitle>

        <InfoSectionList>
          {services.map((service) => (
            <li>
              {service}
            </li>
          ))}


          <InfoLink>
            <NavLink to="/contact">
              Request info
            </NavLink>
          </InfoLink>
          
          <li><br /></li>

          <InfoLink>
            <NavLink to="/mentoring">
              Mentoring sessions
            </NavLink>
          </InfoLink>
        </InfoSectionList>
      </InfoSection>
    </Grid>

    <NextShows>
      <InfoSectionTitle>Next events</InfoSectionTitle>
      
      <ul>
        {conventions.map((convention) => (
          <li>
            <a href={convention.link} target="_blank" rel="noreferrer">
              {convention.title} {convention.location && `(${convention.location})`}
              <span>{convention.date}</span>
            </a>
          </li>
        ))}
      </ul>
    </NextShows>

    <AboutMe showTitle={false} topMargin={false} />

    </Page>
  )
}

export default Home
