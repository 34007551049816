type Convention = {
  date: string,
  location?: string,
  title: string,
  link: string,
}

const conventions: Convention[] = [
  // {
  //   date: '16-17 November 2024',
  //   location: 'Harrogate',
  //   title: 'Thought Bubble Festival',
  //   link: 'https://www.thoughtbubblefestival.com/comicconvention',
  // },
  {
    date: '22 January 2025',
    location: 'Old Spitalfields Market, London',
    title: 'Urban Makers',
    link: 'https://oldspitalfieldsmarket.com/events/urban-makers-wednesdays'
  },
  {
    date: '26 January 2025',
    location: 'Old Spitalfields Market, London',
    title: 'Urban Makers',
    link: 'https://oldspitalfieldsmarket.com/events/guest-market-urban-makers'
  },
  {
    date: '29 January 2025',
    location: 'Old Spitalfields Market, London',
    title: 'Urban Makers',
    link: 'https://oldspitalfieldsmarket.com/events/urban-makers-wednesdays'
  },
  {
    date: '29 March 2025',
    location: 'Old Spitalfields Market, London',
    title: 'Urban Makers',
    link: 'https://oldspitalfieldsmarket.com/events/guest-market-urban-makers'
  },
  {
    date: 'March and April - Every Wednesday',
    location: 'Old Spitalfields Market, London',
    title: 'Urban Makers',
    link: 'https://oldspitalfieldsmarket.com/events/urban-makers-wednesdays'
  },
  {
    date: '5-6 July',
    location: 'Olympia London',
    title: 'London Film & Comic Con',
    link: 'https://londonfilmandcomiccon.com'
  },
  {
    date: '1-31 July 2025',
    // location: 'Online',
    title: 'LDC Online Comics Fair',
    link: 'https://ldcomics.com/ldcomics-online-fair-2025/'
  }
];

export default conventions;
