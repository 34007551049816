export enum IllustrationType {
  BookCoverDesign = 'Book Cover Design',
  FullPageIllustration = 'Full Page Illustration',
  ConceptArt = 'Concept Art',
  CharacterDesign = 'Character Design',
  EnvironmentDesign = 'Environment Design',
  Sketches = 'Sketches',
  ComicBookPages = 'Comic Book Page',
  ChildrensBooks = 'Childrens Books Illustration',
  Editorial = 'Editorial Illustration',
  Others = 'Illustration',
}

enum IllustrationMainColour {
  Blue = 'Blue',
  Red = 'Red',
  Green = 'Green',
  Yellow = 'Yellow',
  Pink = 'Pink',
  Purple = 'Purple',
  Orange = 'Orange',
  Brown = 'Brown',
  Black = 'Black',
  White = 'White',
  Grey = 'Grey',
}

export type PortfolioImage = {
  imageUrl: string
  imageMidSize: string
  imageThumbnail: string
  slug: string
  type: IllustrationType[]
  colour: IllustrationMainColour[]
  description?: string
  spanColumns?: number
  preventCropping?: boolean,
  hideFromGallery?: boolean,
}

export type Project = {
  slug: string
  title: string
  description: string[]
  date: string
  images: PortfolioImage[]
  externalLinks?: { link: string, title: string }[]
  reviews?: { snippet: string, author?: string, publication: string, link: string }[]
}

export type PortfolioImageKeys = [string, string][];

export const projects: Project[] = [{
  slug: 'thelastdayofrain',
  title: 'The Last Day of Rain - Comic Book Series',
  date: '2021 - 2024',
  description: [
    "The Last Day of Rain is a comic book series in a fictional future London where it doesn't rain due to escalating global warming.",
    "Carol is a nanobot engineer working on a secret project to bring rain back to the city. When her research is stolen, she is forced to face her past and learn to rely on others: otherwise, her dream is at risk!",
    "The final two issues are due to be released until early next year.",
    "Illustration and story by Claudia Matosa.",
    "Tools: Clip Studio Paint, Photoshop, Blender 3D and Affinity Publisher."
  ],
  externalLinks: [
    { link: 'https://thelastdayofrain.com', title: 'Official website' },
    { link: 'https://claudiamatosa.com/shop/the-last-day-of-rain', title: 'Order online' },
    { link: 'https://thelastdayofrain.com/en-GB/reviews', title: 'Read reviews' }
  ],
  images: [{
    imageUrl: require('../components/portfolio/images/illustration/lastdayofrain-1.jpg'),
    imageThumbnail: require('../components/portfolio/images/illustration/thumbnails/lastdayofrain-1.jpg'),
    imageMidSize: require('../components/portfolio/images/illustration/midSize/lastdayofrain-1.jpg'),
    slug: 'illustration-lastdayofrain-1',
    type: [IllustrationType.FullPageIllustration, IllustrationType.ConceptArt, IllustrationType.EnvironmentDesign],
    colour: [IllustrationMainColour.Purple],
  }, {
    imageUrl: require('../components/portfolio/images/printed/ldor-10.jpg'),
    imageThumbnail: require('../components/portfolio/images/printed/thumbnails/ldor-10.jpg'),
    imageMidSize: require('../components/portfolio/images/printed/midSize/ldor-10.jpg'),
    slug: 'printed-ldor-10',
    type: [IllustrationType.BookCoverDesign],
    colour: [IllustrationMainColour.Purple, IllustrationMainColour.Blue, IllustrationMainColour.Orange],
    // spanColumns: 2,
  }, {
    imageUrl: require('../components/portfolio/images/illustration/lastdayofrain-2.jpg'),
    imageThumbnail: require('../components/portfolio/images/illustration/thumbnails/lastdayofrain-2.jpg'),
    imageMidSize: require('../components/portfolio/images/illustration/midSize/lastdayofrain-2.jpg'),
    slug: 'illustration-lastdayofrain-2',
    type: [IllustrationType.FullPageIllustration, IllustrationType.ConceptArt, IllustrationType.EnvironmentDesign],
    colour: [IllustrationMainColour.Red, IllustrationMainColour.Orange],
  }, {
    imageUrl: require('../components/portfolio/images/illustration/lastdayofrain-3.jpg'),
    imageThumbnail: require('../components/portfolio/images/illustration/thumbnails/lastdayofrain-3.jpg'),
    imageMidSize: require('../components/portfolio/images/illustration/midSize/lastdayofrain-3.jpg'),
    slug: 'illustration-lastdayofrain-3',
    type: [IllustrationType.FullPageIllustration, IllustrationType.ConceptArt],
    colour: [IllustrationMainColour.Purple, IllustrationMainColour.Orange],
    spanColumns: 2,
    hideFromGallery: true,
  }, {
    imageUrl: require('../components/portfolio/images/illustration/lastdayofrain-4.jpg'),
    imageThumbnail: require('../components/portfolio/images/illustration/thumbnails/lastdayofrain-4.jpg'),
    imageMidSize: require('../components/portfolio/images/illustration/midSize/lastdayofrain-4.jpg'),
    slug: 'illustration-lastdayofrain-4',
    type: [IllustrationType.FullPageIllustration, IllustrationType.ConceptArt, IllustrationType.CharacterDesign],
    colour: [IllustrationMainColour.Purple, IllustrationMainColour.Yellow, IllustrationMainColour.Red],
  }, {
    imageUrl: require('../components/portfolio/images/illustration/lastdayofrain-5.jpg'),
    imageThumbnail: require('../components/portfolio/images/illustration/thumbnails/lastdayofrain-5.jpg'),
    imageMidSize: require('../components/portfolio/images/illustration/midSize/lastdayofrain-5.jpg'),
    slug: 'illustration-lastdayofrain-5',
    type: [IllustrationType.FullPageIllustration, IllustrationType.CharacterDesign],
    colour: [IllustrationMainColour.Purple, IllustrationMainColour.Orange],
  }, {
    imageUrl: require('../components/portfolio/images/illustration/lastdayofrain-6.jpg'),
    imageThumbnail: require('../components/portfolio/images/illustration/thumbnails/lastdayofrain-6.jpg'),
    imageMidSize: require('../components/portfolio/images/illustration/midSize/lastdayofrain-6.jpg'),
    slug: 'illustration-lastdayofrain-6',
    type: [IllustrationType.FullPageIllustration, IllustrationType.ConceptArt, IllustrationType.EnvironmentDesign],
    colour: [IllustrationMainColour.Purple, IllustrationMainColour.Pink],
  }, {
    imageUrl: require('../components/portfolio/images/illustration/lastdayofrain-7.jpg'),
    imageThumbnail: require('../components/portfolio/images/illustration/thumbnails/lastdayofrain-7.jpg'),
    imageMidSize: require('../components/portfolio/images/illustration/midSize/lastdayofrain-7.jpg'),
    slug: 'illustration-lastdayofrain-7',
    type: [IllustrationType.FullPageIllustration, IllustrationType.ConceptArt, IllustrationType.EnvironmentDesign],
    colour: [IllustrationMainColour.Purple, IllustrationMainColour.Orange, IllustrationMainColour.Pink],
  },  {
    imageUrl: require('../components/portfolio/images/illustration/lastdayofrain-8.jpg'),
    imageThumbnail: require('../components/portfolio/images/illustration/thumbnails/lastdayofrain-8.jpg'),
    imageMidSize: require('../components/portfolio/images/illustration/midSize/lastdayofrain-8.jpg'),
    slug: 'illustration-lastdayofrain-8',
    type: [IllustrationType.FullPageIllustration, IllustrationType.ConceptArt, IllustrationType.ComicBookPages, IllustrationType.EnvironmentDesign],
    colour: [IllustrationMainColour.Purple, IllustrationMainColour.Blue, IllustrationMainColour.Green],
  }, {
    imageUrl: require('../components/portfolio/images/printed/ldor-1.jpg'),
    imageThumbnail: require('../components/portfolio/images/printed/thumbnails/ldor-1.jpg'),
    imageMidSize: require('../components/portfolio/images/printed/midSize/ldor-1.jpg'),
    slug: 'printed-ldor-1',
    type: [IllustrationType.BookCoverDesign],
    colour: [IllustrationMainColour.Blue, IllustrationMainColour.Orange],
    // spanColumns: 2,
  },  {
    imageUrl: require('../components/portfolio/images/printed/ldor-4.jpg'),
    imageThumbnail: require('../components/portfolio/images/printed/thumbnails/ldor-4.jpg'),
    imageMidSize: require('../components/portfolio/images/printed/midSize/ldor-4.jpg'),
    slug: 'printed-ldor-4',
    type: [IllustrationType.BookCoverDesign],
    colour: [IllustrationMainColour.Purple, IllustrationMainColour.Orange],
    // spanColumns: 2,
  }, {
    imageUrl: require('../components/portfolio/images/printed/ldor-7.jpg'),
    imageThumbnail: require('../components/portfolio/images/printed/thumbnails/ldor-7.jpg'),
    imageMidSize: require('../components/portfolio/images/printed/midSize/ldor-7.jpg'),
    slug: 'printed-ldor-7',
    type: [IllustrationType.BookCoverDesign],
    colour: [IllustrationMainColour.Purple, IllustrationMainColour.Pink],
    // spanColumns: 2,
  }, {
    imageUrl: require('../components/portfolio/images/printed/ldor-2.jpg'),
    imageThumbnail: require('../components/portfolio/images/printed/thumbnails/ldor-2.jpg'),
    imageMidSize: require('../components/portfolio/images/printed/midSize/ldor-2.jpg'),
    slug: 'printed-ldor-2',
    type: [IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Purple, IllustrationMainColour.Orange],
    // spanColumns: 2,
  }, {
    imageUrl: require('../components/portfolio/images/printed/ldor-3.jpg'),
    imageThumbnail: require('../components/portfolio/images/printed/thumbnails/ldor-3.jpg'),
    imageMidSize: require('../components/portfolio/images/printed/midSize/ldor-3.jpg'),
    slug: 'printed-ldor-3',
    type: [IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Blue, IllustrationMainColour.Yellow],
    // spanColumns: 2,
  }, {
    imageUrl: require('../components/portfolio/images/printed/ldor-5.jpg'),
    imageThumbnail: require('../components/portfolio/images/printed/thumbnails/ldor-5.jpg'),
    imageMidSize: require('../components/portfolio/images/printed/midSize/ldor-5.jpg'),
    slug: 'printed-ldor-5',
    type: [IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Orange, IllustrationMainColour.Red],
    // spanColumns: 2,
  }, {
  //   imageUrl: require('../components/portfolio/images/printed/ldor-6.jpg'),
  //   imageThumbnail: require('../components/portfolio/images/printed/thumbnails/ldor-6.jpg'),
  //   imageMidSize: require('../components/portfolio/images/printed/midSize/ldor-6.jpg'),
  //   slug: 'printed-ldor-6',
  //   type: [IllustrationType.ComicBookPages],
  //   colour: [IllustrationMainColour.Purple, IllustrationMainColour.Orange],
  //   // spanColumns: 2,
  // }, {
    imageUrl: require('../components/portfolio/images/printed/ldor-8.jpg'),
    imageThumbnail: require('../components/portfolio/images/printed/thumbnails/ldor-8.jpg'),
    imageMidSize: require('../components/portfolio/images/printed/midSize/ldor-8.jpg'),
    slug: 'printed-ldor-8',
    type: [IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Purple, IllustrationMainColour.Orange],
    // spanColumns: 2,
  }, {
    imageUrl: require('../components/portfolio/images/printed/ldor-9.jpg'),
    imageThumbnail: require('../components/portfolio/images/printed/thumbnails/ldor-9.jpg'),
    imageMidSize: require('../components/portfolio/images/printed/midSize/ldor-9.jpg'),
    slug: 'printed-ldor-9',
    type: [IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Purple],
    // spanColumns: 2,
  }, {
    imageUrl: require('../components/portfolio/images/comics/ldor-1.jpg'),
    imageThumbnail: require('../components/portfolio/images/comics/thumbnails/ldor-1.jpg'),
    imageMidSize: require('../components/portfolio/images/comics/midSize/ldor-1.jpg'),
    slug: 'comics-ldor-1',
    type: [IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Pink, IllustrationMainColour.Green],
  }, {
    imageUrl: require('../components/portfolio/images/comics/ldor-2.jpg'),
    imageThumbnail: require('../components/portfolio/images/comics/thumbnails/ldor-2.jpg'),
    imageMidSize: require('../components/portfolio/images/comics/midSize/ldor-2.jpg'),
    slug: 'comics-ldor-2',
    type: [IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Blue],
    preventCropping: true,
  }, {
  //   imageUrl: require('../components/portfolio/images/comics/ldor-3.jpg'),
  //   imageThumbnail: require('../components/portfolio/images/comics/thumbnails/ldor-3.jpg'),
  //   imageMidSize: require('../components/portfolio/images/comics/midSize/ldor-3.jpg'),
  //   slug: 'comics-ldor-3',
  //   type: [IllustrationType.ComicBookPages],
  //   colour: [IllustrationMainColour.Red, IllustrationMainColour.Pink],
  // }, {
    imageUrl: require('../components/portfolio/images/comics/ldor-4.jpg'),
    imageThumbnail: require('../components/portfolio/images/comics/thumbnails/ldor-4.jpg'),
    imageMidSize: require('../components/portfolio/images/comics/midSize/ldor-4.jpg'),
    slug: 'comics-ldor-4',
    type: [IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Orange, IllustrationMainColour.Pink],
  }, {
    imageUrl: require('../components/portfolio/images/comics/ldor-5.jpg'),
    imageThumbnail: require('../components/portfolio/images/comics/thumbnails/ldor-5.jpg'),
    imageMidSize: require('../components/portfolio/images/comics/midSize/ldor-5.jpg'),
    slug: 'comics-ldor-5',
    type: [IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Blue, IllustrationMainColour.Yellow],
  }, {
    imageUrl: require('../components/portfolio/images/comics/ldor-6.jpg'),
    imageThumbnail: require('../components/portfolio/images/comics/thumbnails/ldor-6.jpg'),
    imageMidSize: require('../components/portfolio/images/comics/midSize/ldor-6.jpg'),
    slug: 'comics-ldor-6',
    type: [IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Pink],
  }, {
    imageUrl: require('../components/portfolio/images/comics/ldor-7.jpg'),
    imageThumbnail: require('../components/portfolio/images/comics/thumbnails/ldor-7.jpg'),
    imageMidSize: require('../components/portfolio/images/comics/midSize/ldor-7.jpg'),
    slug: 'comics-ldor-7',
    type: [IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Pink, IllustrationMainColour.Purple],
    }, {
      imageUrl: require('../components/portfolio/images/comics/ldor-8.jpg'),
      imageThumbnail: require('../components/portfolio/images/comics/thumbnails/ldor-8.jpg'),
      imageMidSize: require('../components/portfolio/images/comics/midSize/ldor-8.jpg'),
      slug: 'comics-ldor-8',
      type: [IllustrationType.ComicBookPages],
      colour: [IllustrationMainColour.Purple],
    }, {
      imageUrl: require('../components/portfolio/images/comics/ldor-21.jpg'),
      imageThumbnail: require('../components/portfolio/images/comics/thumbnails/ldor-21.jpg'),
      imageMidSize: require('../components/portfolio/images/comics/midSize/ldor-21.jpg'),
      slug: 'comics-ldor-21',
      type: [IllustrationType.ComicBookPages],
      colour: [IllustrationMainColour.Blue, IllustrationMainColour.Green, IllustrationMainColour.Purple],
    }, {
      imageUrl: require('../components/portfolio/images/comics/ldor-22.jpg'),
      imageThumbnail: require('../components/portfolio/images/comics/thumbnails/ldor-22.jpg'),
      imageMidSize: require('../components/portfolio/images/comics/midSize/ldor-22.jpg'),
      slug: 'comics-ldor-22',
      type: [IllustrationType.ComicBookPages],
      colour: [IllustrationMainColour.Blue, IllustrationMainColour.Green, IllustrationMainColour.Purple],
    }, {
      imageUrl: require('../components/portfolio/images/comics/ldor-9.jpg'),
      imageThumbnail: require('../components/portfolio/images/comics/thumbnails/ldor-9.jpg'),
      imageMidSize: require('../components/portfolio/images/comics/midSize/ldor-9.jpg'),
      slug: 'comics-ldor-9',
      type: [IllustrationType.ComicBookPages],
      colour: [IllustrationMainColour.Purple, IllustrationMainColour.Orange],
    }, {
  //   imageUrl: require('../components/portfolio/images/comics/ldor-10.jpg'),
  //   imageThumbnail: require('../components/portfolio/images/comics/thumbnails/ldor-10.jpg'),
  //   imageMidSize: require('../components/portfolio/images/comics/midSize/ldor-10.jpg'),
  //   slug: 'comics-ldor-10',
  //   type: [IllustrationType.ComicBookPages],
  //   colour: [IllustrationMainColour.Yellow, IllustrationMainColour.Pink],
  // }, {
    imageUrl: require('../components/portfolio/images/comics/ldor-11.jpg'),
    imageThumbnail: require('../components/portfolio/images/comics/thumbnails/ldor-11.jpg'),
    imageMidSize: require('../components/portfolio/images/comics/midSize/ldor-11.jpg'),
    slug: 'comics-ldor-11',
    type: [IllustrationType.ComicBookPages, IllustrationType.EnvironmentDesign],
    colour: [IllustrationMainColour.Brown, IllustrationMainColour.Orange],
  }, {
    imageUrl: require('../components/portfolio/images/comics/ldor-12.jpg'),
    imageThumbnail: require('../components/portfolio/images/comics/thumbnails/ldor-12.jpg'),
    imageMidSize: require('../components/portfolio/images/comics/midSize/ldor-12.jpg'),
    slug: 'comics-ldor-12',
    type: [IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Purple],
  }, {
    imageUrl: require('../components/portfolio/images/comics/ldor-13.jpg'),
    imageThumbnail: require('../components/portfolio/images/comics/thumbnails/ldor-13.jpg'),
    imageMidSize: require('../components/portfolio/images/comics/midSize/ldor-13.jpg'),
    slug: 'comics-ldor-13',
    type: [IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Orange, IllustrationMainColour.Purple],
    
  }, {
    imageUrl: require('../components/portfolio/images/comics/ldor-14.jpg'),
    imageThumbnail: require('../components/portfolio/images/comics/thumbnails/ldor-14.jpg'),
    imageMidSize: require('../components/portfolio/images/comics/midSize/ldor-14.jpg'),
    slug: 'comics-ldor-14',
    type: [IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Orange, IllustrationMainColour.Pink],
  }, {
  //   imageUrl: require('../components/portfolio/images/comics/ldor-15.jpg'),
  //   imageThumbnail: require('../components/portfolio/images/comics/thumbnails/ldor-15.jpg'),
  //   imageMidSize: require('../components/portfolio/images/comics/midSize/ldor-15.jpg'),
  //   slug: 'comics-ldor-15',
  //   type: [IllustrationType.ComicBookPages],
  //   colour: [IllustrationMainColour.Purple, IllustrationMainColour.Pink],
  // }, {
    imageUrl: require('../components/portfolio/images/comics/ldor-16.jpg'),
    imageThumbnail: require('../components/portfolio/images/comics/thumbnails/ldor-16.jpg'),
    imageMidSize: require('../components/portfolio/images/comics/midSize/ldor-16.jpg'),
    slug: 'comics-ldor-16',
    type: [IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Orange, IllustrationMainColour.Pink],
  }, {
    imageUrl: require('../components/portfolio/images/comics/ldor-17.jpg'),
    imageThumbnail: require('../components/portfolio/images/comics/thumbnails/ldor-17.jpg'),
    imageMidSize: require('../components/portfolio/images/comics/midSize/ldor-17.jpg'),
    slug: 'comics-ldor-17',
    type: [IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Red],
  }, {
    imageUrl: require('../components/portfolio/images/comics/ldor-18.jpg'),
    imageThumbnail: require('../components/portfolio/images/comics/thumbnails/ldor-18.jpg'),
    imageMidSize: require('../components/portfolio/images/comics/midSize/ldor-18.jpg'),
    slug: 'comics-ldor-18',
    type: [IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Orange, IllustrationMainColour.Purple],
  }, {
    imageUrl: require('../components/portfolio/images/comics/ldor-19.jpg'),
    imageThumbnail: require('../components/portfolio/images/comics/thumbnails/ldor-19.jpg'),
    imageMidSize: require('../components/portfolio/images/comics/midSize/ldor-19.jpg'),
    slug: 'comics-ldor-19',
    type: [IllustrationType.ComicBookPages, IllustrationType.CharacterDesign],
    colour: [IllustrationMainColour.Blue, IllustrationMainColour.Green],
    spanColumns: 2,
  }, {
    imageUrl: require('../components/portfolio/images/comics/ldor-20.jpg'),
    imageThumbnail: require('../components/portfolio/images/comics/thumbnails/ldor-20.jpg'),
    imageMidSize: require('../components/portfolio/images/comics/midSize/ldor-20.jpg'),
    slug: 'comics-ldor-20',
    type: [IllustrationType.ComicBookPages, IllustrationType.EnvironmentDesign],
    colour: [IllustrationMainColour.Pink, IllustrationMainColour.Purple],
  }, {
    imageUrl: require('../components/portfolio/images/snippets/ldor-1.jpg'),
    imageThumbnail: require('../components/portfolio/images/snippets/thumbnails/ldor-1.jpg'),
    imageMidSize: require('../components/portfolio/images/snippets/midSize/ldor-1.jpg'),
    slug: 'snippets-ldor-1',
    type: [IllustrationType.Others, IllustrationType.CharacterDesign],
    colour: [IllustrationMainColour.Orange],
    spanColumns: 2,
    preventCropping: true,
  }, {
    imageUrl: require('../components/portfolio/images/snippets/ldor-2.jpg'),
    imageThumbnail: require('../components/portfolio/images/snippets/thumbnails/ldor-2.jpg'),
    imageMidSize: require('../components/portfolio/images/snippets/midSize/ldor-2.jpg'),
    slug: 'snippets-ldor-2',
    type: [IllustrationType.Others],
    colour: [IllustrationMainColour.Blue, IllustrationMainColour.Orange],
    spanColumns: 2,
    preventCropping: true,
  }, {
    imageUrl: require('../components/portfolio/images/covers/ldor-3.jpg'),
    imageThumbnail: require('../components/portfolio/images/covers/thumbnails/ldor-3.jpg'),
    imageMidSize: require('../components/portfolio/images/covers/midSize/ldor-3.jpg'),
    slug: 'covers-ldor-3',
    type: [IllustrationType.BookCoverDesign],
    colour: [IllustrationMainColour.Purple, IllustrationMainColour.Pink],
  }, {
    imageUrl: require('../components/portfolio/images/covers/ldor-4.jpg'),
    imageThumbnail: require('../components/portfolio/images/covers/thumbnails/ldor-4.jpg'),
    imageMidSize: require('../components/portfolio/images/covers/midSize/ldor-4.jpg'),
    slug: 'covers-ldor-4',
    type: [IllustrationType.BookCoverDesign],
    colour: [IllustrationMainColour.Purple, IllustrationMainColour.Yellow],
  }, {
    imageUrl: require('../components/portfolio/images/covers/ldor-5.jpg'),
    imageThumbnail: require('../components/portfolio/images/covers/thumbnails/ldor-5.jpg'),
    imageMidSize: require('../components/portfolio/images/covers/midSize/ldor-5.jpg'),
    slug: 'covers-ldor-5',
    type: [IllustrationType.BookCoverDesign],
    colour: [IllustrationMainColour.Purple, IllustrationMainColour.Yellow],
  }, {
    imageUrl: require('../components/portfolio/images/covers/ldor-6.jpg'),
    imageThumbnail: require('../components/portfolio/images/covers/thumbnails/ldor-6.jpg'),
    imageMidSize: require('../components/portfolio/images/covers/midSize/ldor-6.jpg'),
    slug: 'covers-ldor-6',
    type: [IllustrationType.BookCoverDesign],
    colour: [IllustrationMainColour.Yellow],
  }, {
    imageUrl: require('../components/portfolio/images/covers/ldor-7.jpg'),
    imageThumbnail: require('../components/portfolio/images/covers/thumbnails/ldor-7.jpg'),
    imageMidSize: require('../components/portfolio/images/covers/midSize/ldor-7.jpg'),
    slug: 'covers-ldor-7',
    type: [IllustrationType.BookCoverDesign],
    colour: [IllustrationMainColour.Purple, IllustrationMainColour.Orange],
  }, {
    imageUrl: require('../components/portfolio/images/covers/ldor-8.jpg'),
    imageThumbnail: require('../components/portfolio/images/covers/thumbnails/ldor-8.jpg'),
    imageMidSize: require('../components/portfolio/images/covers/midSize/ldor-8.jpg'),
    slug: 'covers-ldor-8',
    type: [IllustrationType.BookCoverDesign],
    colour: [IllustrationMainColour.Red, IllustrationMainColour.Orange],
  }, {
    imageUrl: require('../components/portfolio/images/covers/ldor-1.jpg'),
    imageThumbnail: require('../components/portfolio/images/covers/thumbnails/ldor-1.jpg'),
    imageMidSize: require('../components/portfolio/images/covers/midSize/ldor-1.jpg'),
    slug: 'covers-ldor-1',
    type: [IllustrationType.BookCoverDesign],
    colour: [IllustrationMainColour.Blue, IllustrationMainColour.Orange],
  }, {
    imageUrl: require('../components/portfolio/images/covers/ldor-2.jpg'),
    imageThumbnail: require('../components/portfolio/images/covers/thumbnails/ldor-2.jpg'),
    imageMidSize: require('../components/portfolio/images/covers/midSize/ldor-2.jpg'),
    slug: 'covers-ldor-2',
    type: [IllustrationType.BookCoverDesign],
    colour: [IllustrationMainColour.Blue],
  }, {
    imageUrl: require('../components/portfolio/images/characters/ldor-1.jpg'),
    imageThumbnail: require('../components/portfolio/images/characters/thumbnails/ldor-1.jpg'),
    imageMidSize: require('../components/portfolio/images/characters/midSize/ldor-1.jpg'),
    slug: 'characters-ldor-1',
    type: [IllustrationType.CharacterDesign],
    colour: [IllustrationMainColour.Purple, IllustrationMainColour.Orange, IllustrationMainColour.Pink],
    spanColumns: 2,
    // preventCropping: true,
  }, {
    imageUrl: require('../components/portfolio/images/characters/ldor-2.jpg'),
    imageThumbnail: require('../components/portfolio/images/characters/thumbnails/ldor-2.jpg'),
    imageMidSize: require('../components/portfolio/images/characters/midSize/ldor-2.jpg'),
    slug: 'characters-ldor-2',
    type: [IllustrationType.CharacterDesign],
    colour: [IllustrationMainColour.Purple,IllustrationMainColour.Pink],
  }, {
    imageUrl: require('../components/portfolio/images/characters/ldor-3.jpg'),
    imageThumbnail: require('../components/portfolio/images/characters/thumbnails/ldor-3.jpg'),
    imageMidSize: require('../components/portfolio/images/characters/midSize/ldor-3.jpg'),
    slug: 'characters-ldor-3',
    type: [IllustrationType.CharacterDesign],
    colour: [IllustrationMainColour.Purple,IllustrationMainColour.Pink],
    spanColumns: 2,
  }, {
    imageUrl: require('../components/portfolio/images/sketches/ldor-3.jpg'),
    imageThumbnail: require('../components/portfolio/images/sketches/thumbnails/ldor-3.jpg'),
    imageMidSize: require('../components/portfolio/images/sketches/midSize/ldor-3.jpg'),
    slug: 'sketches-ldor-3',
    type: [IllustrationType.Sketches],
    colour: [IllustrationMainColour.Black],
  }, {
    imageUrl: require('../components/portfolio/images/sketches/ldor-2.jpg'),
    imageThumbnail: require('../components/portfolio/images/sketches/thumbnails/ldor-2.jpg'),
    imageMidSize: require('../components/portfolio/images/sketches/midSize/ldor-2.jpg'),
    slug: 'sketches-ldor-2',
    type: [IllustrationType.Sketches],
    colour: [IllustrationMainColour.Red],
  }, {
    imageUrl: require('../components/portfolio/images/sketches/ldor-1.jpg'),
    imageThumbnail: require('../components/portfolio/images/sketches/thumbnails/ldor-1.jpg'),
    imageMidSize: require('../components/portfolio/images/sketches/midSize/ldor-1.jpg'),
    slug: 'sketches-ldor-1',
    type: [IllustrationType.Sketches],
    colour: [IllustrationMainColour.Purple, IllustrationMainColour.Pink],
    description: 'Rough layout sketches for the pages of The Last Day of Rain, chapter 3.',
    spanColumns: 2,
  }, {
    imageUrl: require('../components/portfolio/images/covers/ldor-cover-and-back.jpg'),
    imageThumbnail: require('../components/portfolio/images/covers/thumbnails/ldor-cover-and-back.jpg'),
    imageMidSize: require('../components/portfolio/images/covers/midSize/ldor-cover-and-back.jpg'),
    slug: 'cover-and-back-ldor',
    type: [IllustrationType.BookCoverDesign],
    colour: [IllustrationMainColour.Blue, IllustrationMainColour.Orange],
  }, {
    imageUrl: require('../components/portfolio/images/covers/ldor-cover-and-back-2.jpg'),
    imageThumbnail: require('../components/portfolio/images/covers/thumbnails/ldor-cover-and-back-2.jpg'),
    imageMidSize: require('../components/portfolio/images/covers/midSize/ldor-cover-and-back-2.jpg'),
    slug: 'cover-and-back-ldor-2',
    type: [IllustrationType.BookCoverDesign],
    colour: [IllustrationMainColour.Blue, IllustrationMainColour.Orange],
  }],
}, {
  slug: 'shibatown',
  title: 'Shiba Town - The Last Day of Rain',
  date: '2021 - 2024',
  description: [
    "Shiba Town is a fictional underground neighbourhood situated in South London, where Surrey Quays used to be. It was created for The Last Day of Rain comic book series.",
    "Its roof, made with solar panels, is held in place by monumental concrete columns and steel cables, taking inspiration from the design of suspension bridges.",
    "Blender 3D was used to create a rough map of the city, that could be used as an under sketch for the comic book pages.",
    "Tools: Clip Studio Paint, Photoshop, Blender 3D and Affinity Publisher."
  ],
  externalLinks: [
    { link: 'https://thelastdayofrain.com', title: 'The Last Day of Rain' },
    { link: 'https://claudiamatosa.com/shop/the-last-day-of-rain', title: 'Order online' },
  ],
  images: [{
    imageUrl: require('../components/portfolio/images/illustration/lastdayofrain-3.jpg'),
    imageThumbnail: require('../components/portfolio/images/illustration/thumbnails/lastdayofrain-3.jpg'),
    imageMidSize: require('../components/portfolio/images/illustration/midSize/lastdayofrain-3.jpg'),
    slug: 'illustration-lastdayofrain-3',
    type: [IllustrationType.FullPageIllustration, IllustrationType.ConceptArt, IllustrationType.EnvironmentDesign],
    colour: [IllustrationMainColour.Orange, IllustrationMainColour.Purple],
    spanColumns: 2,
  }, {
    imageUrl: require('../components/portfolio/images/sketches/shibatown-1.jpg'),
    imageThumbnail: require('../components/portfolio/images/sketches/thumbnails/shibatown-1.jpg'),
    imageMidSize: require('../components/portfolio/images/sketches/midSize/shibatown-1.jpg'),
    slug: 'sketches-shibatown-1',
    type: [IllustrationType.Sketches, IllustrationType.EnvironmentDesign],
    colour: [IllustrationMainColour.Orange, IllustrationMainColour.Purple],
    spanColumns: 2,
  }, {
    imageUrl: require('../components/portfolio/images/illustration/lastdayofrain-1.jpg'),
    imageThumbnail: require('../components/portfolio/images/illustration/thumbnails/lastdayofrain-1.jpg'),
    imageMidSize: require('../components/portfolio/images/illustration/midSize/lastdayofrain-1.jpg'),
    slug: 'illustration-lastdayofrain-1',
    type: [IllustrationType.FullPageIllustration, IllustrationType.ConceptArt],
    colour: [IllustrationMainColour.Purple],
    hideFromGallery: true,
  }, {
    imageUrl: require('../components/portfolio/images/comics/ldor-21.jpg'),
    imageThumbnail: require('../components/portfolio/images/comics/thumbnails/ldor-21.jpg'),
    imageMidSize: require('../components/portfolio/images/comics/midSize/ldor-21.jpg'),
    slug: 'comics-ldor-21',
    type: [IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Blue, IllustrationMainColour.Green, IllustrationMainColour.Purple],
    hideFromGallery: true,
  }, {
    imageUrl: require('../components/portfolio/images/illustration/lastdayofrain-8.jpg'),
    imageThumbnail: require('../components/portfolio/images/illustration/thumbnails/lastdayofrain-8.jpg'),
    imageMidSize: require('../components/portfolio/images/illustration/midSize/lastdayofrain-8.jpg'),
    slug: 'illustration-lastdayofrain-8',
    type: [IllustrationType.FullPageIllustration, IllustrationType.ConceptArt, IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Purple, IllustrationMainColour.Blue, IllustrationMainColour.Green],
    hideFromGallery: true,
  }, {
    imageUrl: require('../components/portfolio/images/comics/ldor-18.jpg'),
    imageThumbnail: require('../components/portfolio/images/comics/thumbnails/ldor-18.jpg'),
    imageMidSize: require('../components/portfolio/images/comics/midSize/ldor-18.jpg'),
    slug: 'comics-ldor-18',
    type: [IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Orange, IllustrationMainColour.Purple],
    hideFromGallery: true,
  }, {
    imageUrl: require('../components/portfolio/images/sketches/shibatown-2.jpg'),
    imageThumbnail: require('../components/portfolio/images/sketches/thumbnails/shibatown-2.jpg'),
    imageMidSize: require('../components/portfolio/images/sketches/midSize/shibatown-2.jpg'),
    slug: 'sketches-shibatown-2',
    type: [IllustrationType.Sketches],
    colour: [IllustrationMainColour.Grey, IllustrationMainColour.Purple, IllustrationMainColour.Green],
    spanColumns: 2,
    description: 'Map of Shiba Town, used as concept art to create the 3D sketches of the neighbourhood, and to help locate the characters as they walk about the comic book.',
  }, {
    imageUrl: require('../components/portfolio/images/sketches/shibatown-3.jpg'),
    imageThumbnail: require('../components/portfolio/images/sketches/thumbnails/shibatown-3.jpg'),
    imageMidSize: require('../components/portfolio/images/sketches/midSize/shibatown-3.jpg'),
    slug: 'sketches-shibatown-3',
    type: [IllustrationType.Sketches],
    colour: [IllustrationMainColour.Grey],
    spanColumns: 2,
    description: '3D sketch of the interior of Shiba Town, without the roof. The buildings on the surface were imported in Blender from Google Maps.'
  }, {
    imageUrl: require('../components/portfolio/images/sketches/shibatown-4.jpg'),
    imageThumbnail: require('../components/portfolio/images/sketches/thumbnails/shibatown-4.jpg'),
    imageMidSize: require('../components/portfolio/images/sketches/midSize/shibatown-4.jpg'),
    slug: 'sketches-shibatown-4',
    type: [IllustrationType.Sketches],
    colour: [IllustrationMainColour.Grey],
    spanColumns: 2,
    description: '3D sketch of Shiba Town\'s solar roof, held by concrete columns. The buildings on the surface were imported in Blender from Google Maps.',
  }]
 }, {
  slug: 'phobos',
  title: 'Phobos - Illustrated Short Story',
  date: '2022',
  description: [
    "Phobos is set in a future where humanity has attempted and failed to colonise Mars, and left a few cities behind. These were taken over by an Artificial Intelligence government, with results less than favourable for its human population.",
    "It follows a couple who splits up due to different views of technology, and finally uncovers the AI's plans to keep people under control.",
    "Illustration and story by Claudia Matosa.",
    "Tools: Clip Studio Paint and Affinity Publisher.",
  ],
  externalLinks: [
    { link: 'https://shop.claudiamatosa.com/products/phobos-illustrated-book-44-94483', title: 'Order online' },
  ],
  images: [{
    imageUrl: require('../components/portfolio/images/printed/phobos-1.jpg'),
    imageThumbnail: require('../components/portfolio/images/printed/thumbnails/phobos-1.jpg'),
    imageMidSize: require('../components/portfolio/images/printed/midSize/phobos-1.jpg'),
    slug: 'printed-phobos-1',
    type: [IllustrationType.BookCoverDesign],
    colour: [IllustrationMainColour.Purple],
    // spanColumns: 2,
  }, {
    imageUrl: require('../components/portfolio/images/illustration/phobos-2.jpg'),
    imageThumbnail: require('../components/portfolio/images/illustration/thumbnails/phobos-2.jpg'),
    imageMidSize: require('../components/portfolio/images/illustration/midSize/phobos-2.jpg'),
    slug: 'illustration-phobos-2',
    type: [IllustrationType.FullPageIllustration, IllustrationType.ConceptArt],
    colour: [IllustrationMainColour.Purple],
  }, {
    imageUrl: require('../components/portfolio/images/illustration/phobos-1.jpg'),
    imageThumbnail: require('../components/portfolio/images/illustration/thumbnails/phobos-1.jpg'),
    imageMidSize: require('../components/portfolio/images/illustration/midSize/phobos-1.jpg'),
    slug: 'illustration-phobos-1',
    type: [IllustrationType.FullPageIllustration, IllustrationType.ConceptArt, IllustrationType.EnvironmentDesign],
    colour: [IllustrationMainColour.Purple],
  }, {
    imageUrl: require('../components/portfolio/images/illustration/phobos-3.jpg'),
    imageThumbnail: require('../components/portfolio/images/illustration/thumbnails/phobos-3.jpg'),
    imageMidSize: require('../components/portfolio/images/illustration/midSize/phobos-3.jpg'),
    slug: 'illustration-phobos-3',
    type: [IllustrationType.FullPageIllustration, IllustrationType.ConceptArt, IllustrationType.CharacterDesign],
    colour: [IllustrationMainColour.Purple, IllustrationMainColour.Brown],
  }, {
    imageUrl: require('../components/portfolio/images/illustration/phobos-4.jpg'),
    imageThumbnail: require('../components/portfolio/images/illustration/thumbnails/phobos-4.jpg'),
    imageMidSize: require('../components/portfolio/images/illustration/midSize/phobos-4.jpg'),
    slug: 'illustration-phobos-4',
    type: [IllustrationType.FullPageIllustration, IllustrationType.ConceptArt, IllustrationType.CharacterDesign],
    colour: [IllustrationMainColour.Red, IllustrationMainColour.Brown],
  }, {
    imageUrl: require('../components/portfolio/images/covers/phobos-1.jpg'),
    imageThumbnail: require('../components/portfolio/images/covers/thumbnails/phobos-1.jpg'),
    imageMidSize: require('../components/portfolio/images/covers/midSize/phobos-1.jpg'),
    slug: 'covers-phobos-1',
    type: [IllustrationType.BookCoverDesign],
    colour: [IllustrationMainColour.Purple],
  }, {
  //   imageUrl: require('../components/portfolio/images/covers/phobos-2.jpg'),
  //   imageThumbnail: require('../components/portfolio/images/covers/thumbnails/phobos-2.jpg'),
  //   imageMidSize   imageThumbnail: require('../components/portfolio/images/covers/midSize/phobos-2.jpg'),
  //   slug: 'covers-phobos-2',
  //   type: [IllustrationType.BookCoverDesign],
  //   colour: [IllustrationMainColour.Purple],
  // }, {
    imageUrl: require('../components/portfolio/images/snippets/phobos-1.jpg'),
    imageThumbnail: require('../components/portfolio/images/snippets/thumbnails/phobos-1.jpg'),
    imageMidSize: require('../components/portfolio/images/snippets/midSize/phobos-1.jpg'),
    slug: 'snippets-phobos-1',
    type: [IllustrationType.Editorial, IllustrationType.EnvironmentDesign],
    colour: [IllustrationMainColour.Purple, IllustrationMainColour.Brown, IllustrationMainColour.Green],
    spanColumns: 2,
    preventCropping: true,
  }, {
    imageUrl: require('../components/portfolio/images/snippets/phobos-2.jpg'),
    imageThumbnail: require('../components/portfolio/images/snippets/thumbnails/phobos-2.jpg'),
    imageMidSize: require('../components/portfolio/images/snippets/midSize/phobos-2.jpg'),
    slug: 'snippets-phobos-2',
    type: [IllustrationType.Editorial],
    colour: [IllustrationMainColour.Purple],
    spanColumns: 2,
    preventCropping: true,
  }, {
    imageUrl: require('../components/portfolio/images/snippets/phobos-3.jpg'),
    imageThumbnail: require('../components/portfolio/images/snippets/thumbnails/phobos-3.jpg'),
    imageMidSize: require('../components/portfolio/images/snippets/midSize/phobos-3.jpg'),
    slug: 'snippets-phobos-3',
    type: [IllustrationType.Editorial],
    colour: [IllustrationMainColour.Purple, IllustrationMainColour.Brown],
    spanColumns: 2,
    preventCropping: true,
  }, {
  //   imageUrl: require('../components/portfolio/images/snippets/phobos-4.jpg'),
  //   imageThumbnail: require('../components/portfolio/images/snippets/thumbnails/phobos-4.jpg'),
  //   imageMidSize   imageThumbnail: require('../components/portfolio/images/snippets/midSize/phobos-4.jpg'),
  //   slug: 'snippets-phobos-4',
  //   type: [IllustrationType.Editorial],
  //   colour: [IllustrationMainColour.Blue],
  //   preventCropping: true,
  // }, {
    imageUrl: require('../components/portfolio/images/snippets/phobos-5.jpg'),
    imageThumbnail: require('../components/portfolio/images/snippets/thumbnails/phobos-5.jpg'),
    imageMidSize: require('../components/portfolio/images/snippets/midSize/phobos-5.jpg'),
    slug: 'snippets-phobos-5',
    type: [IllustrationType.Editorial],
    colour: [IllustrationMainColour.Purple],
    spanColumns: 2,
    preventCropping: true,
  }, {
    imageUrl: require('../components/portfolio/images/printed/phobos-2.jpg'),
    imageThumbnail: require('../components/portfolio/images/printed/thumbnails/phobos-2.jpg'),
    imageMidSize: require('../components/portfolio/images/printed/midSize/phobos-2.jpg'),
    slug: 'printed-phobos-2',
    type: [IllustrationType.Editorial],
    colour: [IllustrationMainColour.Purple],
    // spanColumns: 2,
  }, 
  {
    imageUrl: require('../components/portfolio/images/covers/phobos-cover-and-back.jpg'),
    imageThumbnail: require('../components/portfolio/images/covers/thumbnails/phobos-cover-and-back.jpg'),
    imageMidSize: require('../components/portfolio/images/covers/midSize/phobos-cover-and-back.jpg'),
    slug: 'cover-and-back-phobos',
    type: [IllustrationType.BookCoverDesign],
    colour: [IllustrationMainColour.Purple],
    // spanColumns: 2,
  }],
}, {
  slug: 'theseawallofplymouth',
  title: 'The Sea Wall of Plymouth - Picture Book',
  date: '2023',
  description: [
    "The Sea Wall of Plymouth tells the story of two sisters who live in a future where Plymouth is protected from the rising ocean by a giant sea wall.",
    "Illustration and story by Claudia Matosa.",
    "Tools: Clip Studio Paint and Affinity Publisher.",
  ],
  externalLinks: [
    { link: 'https://shop.claudiamatosa.com/products/the-sea-wall-of-plymouth-comic-book-and-46850', title: 'Order online' },
  ],
  images: [{
    imageUrl: require('../components/portfolio/images/printed/plymouth-1.jpg'),
    imageThumbnail: require('../components/portfolio/images/printed/thumbnails/plymouth-1.jpg'),
    imageMidSize: require('../components/portfolio/images/printed/midSize/plymouth-1.jpg'),
    slug: 'printed-plymouth-1',
    type: [IllustrationType.BookCoverDesign],
    colour: [IllustrationMainColour.Blue],
    // spanColumns: 2,
  }, {
    imageUrl: require('../components/portfolio/images/illustration/seawallofplymouth-3.jpg'),
    imageThumbnail: require('../components/portfolio/images/illustration/thumbnails/seawallofplymouth-3.jpg'),
    imageMidSize: require('../components/portfolio/images/illustration/midSize/seawallofplymouth-3.jpg'),
    slug: 'illustration-seawallofplymouth-3',
    type: [IllustrationType.FullPageIllustration, IllustrationType.ConceptArt],
    colour: [IllustrationMainColour.Purple, IllustrationMainColour.Orange, IllustrationMainColour.Pink],
  }, {
    imageUrl: require('../components/portfolio/images/illustration/seawallofplymouth-1.jpg'),
    imageThumbnail: require('../components/portfolio/images/illustration/thumbnails/seawallofplymouth-1.jpg'),
    imageMidSize: require('../components/portfolio/images/illustration/midSize/seawallofplymouth-1.jpg'),
    slug: 'illustration-seawallofplymouth-1',
    type: [IllustrationType.FullPageIllustration, IllustrationType.ConceptArt],
    colour: [IllustrationMainColour.Blue],
    spanColumns: 2,
  }, {
    imageUrl: require('../components/portfolio/images/illustration/seawallofplymouth-2.jpg'),
    imageThumbnail: require('../components/portfolio/images/illustration/thumbnails/seawallofplymouth-2.jpg'),
    imageMidSize: require('../components/portfolio/images/illustration/midSize/seawallofplymouth-2.jpg'),
    slug: 'illustration-seawallofplymouth-2',
    type: [IllustrationType.FullPageIllustration, IllustrationType.ConceptArt],
    colour: [IllustrationMainColour.Purple, IllustrationMainColour.Blue],
    spanColumns: 2,
  }, {
    imageUrl: require('../components/portfolio/images/printed/plymouth-2.jpg'),
    imageThumbnail: require('../components/portfolio/images/printed/thumbnails/plymouth-2.jpg'),
    imageMidSize: require('../components/portfolio/images/printed/midSize/plymouth-2.jpg'),
    slug: 'printed-plymouth-2',
    type: [IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Blue],
    // spanColumns: 2,
  }, {
    imageUrl: require('../components/portfolio/images/illustration/seawallofplymouth-4.jpg'),
    imageThumbnail: require('../components/portfolio/images/illustration/thumbnails/seawallofplymouth-4.jpg'),
    imageMidSize: require('../components/portfolio/images/illustration/midSize/seawallofplymouth-4.jpg'),
    slug: 'illustration-seawallofplymouth-4',
    type: [IllustrationType.FullPageIllustration, IllustrationType.ConceptArt],
    colour: [IllustrationMainColour.Blue, IllustrationMainColour.Pink],
    spanColumns: 2,
  }, {
    imageUrl: require('../components/portfolio/images/comics/plymouth-1.jpg'),
    imageThumbnail: require('../components/portfolio/images/comics/thumbnails/plymouth-1.jpg'),
    imageMidSize: require('../components/portfolio/images/comics/midSize/plymouth-1.jpg'),
    slug: 'comics-plymouth-1',
    type: [IllustrationType.ComicBookPages, IllustrationType.CharacterDesign],
    colour: [IllustrationMainColour.Green, IllustrationMainColour.Blue],
  }, {
    imageUrl: require('../components/portfolio/images/comics/plymouth-2.jpg'),
    imageThumbnail: require('../components/portfolio/images/comics/thumbnails/plymouth-2.jpg'),
    imageMidSize: require('../components/portfolio/images/comics/midSize/plymouth-2.jpg'),
    slug: 'comics-plymouth-2',
    type: [IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Green, IllustrationMainColour.Blue],
  }, {
    imageUrl: require('../components/portfolio/images/comics/plymouth-3.jpg'),
    imageThumbnail: require('../components/portfolio/images/comics/thumbnails/plymouth-3.jpg'),
    imageMidSize: require('../components/portfolio/images/comics/midSize/plymouth-3.jpg'),
    slug: 'comics-plymouth-3',
    type: [IllustrationType.ComicBookPages, IllustrationType.Editorial, IllustrationType.EnvironmentDesign],
    colour: [IllustrationMainColour.Green, IllustrationMainColour.Blue],
    spanColumns: 2,
  }, {
    imageUrl: require('../components/portfolio/images/covers/plymouth-1.jpg'),
    imageThumbnail: require('../components/portfolio/images/covers/thumbnails/plymouth-1.jpg'),
    imageMidSize: require('../components/portfolio/images/covers/midSize/plymouth-1.jpg'),
    slug: 'covers-plymouth-1',
    type: [IllustrationType.BookCoverDesign],
    colour: [IllustrationMainColour.Blue],
  }, {
    imageUrl: require('../components/portfolio/images/covers/plymouth-2.jpg'),
    imageThumbnail: require('../components/portfolio/images/covers/thumbnails/plymouth-2.jpg'),
    imageMidSize: require('../components/portfolio/images/covers/midSize/plymouth-2.jpg'),
    slug: 'covers-plymouth-2',
    type: [IllustrationType.BookCoverDesign],
    colour: [IllustrationMainColour.Blue],
  }, {
    imageUrl: require('../components/portfolio/images/snippets/plymouth-1.jpg'),
    imageThumbnail: require('../components/portfolio/images/snippets/thumbnails/plymouth-1.jpg'),
    imageMidSize: require('../components/portfolio/images/snippets/midSize/plymouth-1.jpg'),
    slug: 'snippets-plymouth-1',
    type: [IllustrationType.Others, IllustrationType.CharacterDesign],
    colour: [IllustrationMainColour.Blue],
  }, {
    imageUrl: require('../components/portfolio/images/covers/plymouth-cover-and-back.jpg'),
    imageThumbnail: require('../components/portfolio/images/covers/thumbnails/plymouth-cover-and-back.jpg'),
    imageMidSize: require('../components/portfolio/images/covers/midSize/plymouth-cover-and-back.jpg'),
    slug: 'cover-and-back-plymouth',
    type: [IllustrationType.BookCoverDesign],
    colour: [IllustrationMainColour.Blue],
  }, ],
}, {
  slug: 'theverybigsecret',
  title: 'The Very Big Secret - Children\'s Book Illustration',
  date: '2024',
  description: [
    "Cover illustration of a childrens picture book written by Zoë Tucker, as a class assignment for the \"Illustrating Children's Books\" course by Lila Rogers and Zoë herself. This one is different from my usual style: since it's directed to small children, I wanted to use colours as vibrant as possible while conveying the meaning of the story.",
    "Besides book cover design, the course covered character design, environment design, page layout, and everything in the process of working with art directors and agents.",
    "Tools: Clip Studio Paint.",
  ],
  externalLinks: [
    { link: 'https://makeartthatsells.com/online-courses', title: 'Make Art That Sells Courses' },
    { link: 'https://www.zoetucker.co.uk/', title: 'Zoë Tucker' },
  ],
  images: [{
    imageUrl: require('../components/portfolio/images/covers/verybigsecret-1.jpg'),
    imageThumbnail: require('../components/portfolio/images/covers/thumbnails/verybigsecret-1.jpg'),
    imageMidSize: require('../components/portfolio/images/covers/midSize/verybigsecret-1.jpg'),
    slug: 'covers-verybigsecret-1',
    type: [IllustrationType.BookCoverDesign, IllustrationType.ChildrensBooks],
    colour: [IllustrationMainColour.Blue, IllustrationMainColour.Purple, IllustrationMainColour.Pink],
  }]
}, {
    slug: 'worldsapart',
    title: 'Worlds Apart - Mini Comic',
    date: '2022',
    description: [
      "Worlds apart is a short science fiction comic that reveals a story as its panels are unfolded.",
      "Illustration and story by Claudia Matosa.",
      "Tools: Clip Studio Paint, Photoshop, an X-Acto blade and a lot of patience."
    ],
    externalLinks: [
      { link: 'https://shop.claudiamatosa.com/products/worlds-apart-mini-55980', title: 'Order online' },
    ],
    images: [{
      imageUrl: require('../components/portfolio/images/printed/worldsapart-1.jpg'),
      imageThumbnail: require('../components/portfolio/images/printed/thumbnails/worldsapart-1.jpg'),
      imageMidSize: require('../components/portfolio/images/printed/midSize/worldsapart-1.jpg'),
      slug: 'printed-worldsapart-1',
      type: [IllustrationType.Editorial, IllustrationType.ComicBookPages, IllustrationType.BookCoverDesign],
      colour: [IllustrationMainColour.Orange, IllustrationMainColour.Black],
    }, {
      imageUrl: require('../components/portfolio/images/printed/worldsapart-2.jpg'),
      imageThumbnail: require('../components/portfolio/images/printed/thumbnails/worldsapart-2.jpg'),
      imageMidSize: require('../components/portfolio/images/printed/midSize/worldsapart-2.jpg'),
      slug: 'printed-worldsapart-2',
      type: [IllustrationType.Editorial, IllustrationType.ComicBookPages],
      colour: [IllustrationMainColour.Orange, IllustrationMainColour.Black],
    }, {
      imageUrl: require('../components/portfolio/images/printed/worldsapart-3.jpg'),
      imageThumbnail: require('../components/portfolio/images/printed/thumbnails/worldsapart-3.jpg'),
      imageMidSize: require('../components/portfolio/images/printed/midSize/worldsapart-3.jpg'),
      slug: 'printed-worldsapart-3',
      type: [IllustrationType.Editorial, IllustrationType.ComicBookPages],
      colour: [IllustrationMainColour.Orange, IllustrationMainColour.Black],
    }, {
      imageUrl: require('../components/portfolio/images/printed/worldsapart-4.jpg'),
      imageThumbnail: require('../components/portfolio/images/printed/thumbnails/worldsapart-4.jpg'),
      imageMidSize: require('../components/portfolio/images/printed/midSize/worldsapart-4.jpg'),
      slug: 'printed-worldsapart-4',
      type: [IllustrationType.Editorial, IllustrationType.ComicBookPages],
      colour: [IllustrationMainColour.Orange, IllustrationMainColour.Black],
    }]
}, {
  slug: 'violins',
  title: 'Violins - WIP Anthology',
  date: '2023',
  description: [
    "Violins is a short comic contributed to the WIP Change Anthology, a collection of comics by artists from the Work In Progress Comics community. The theme of the anthology was change, and my comic was about change created by depression, who often leads people to not enjoy their most cherished activities anymore.",
    "Written and illustrated by Claudia Matosa.",
    "Tools: Clip Studio Paint.",
  ],
  externalLinks: [
    { link: 'https://www.kickstarter.com/projects/joestone/change-a-wip-comics-anthology', title: 'WIP Change Anthology' },
  ],
  images: [{
    imageUrl: require('../components/portfolio/images/illustration/violinplayer.jpg'),
    imageThumbnail: require('../components/portfolio/images/illustration/thumbnails/violinplayer.jpg'),
    imageMidSize: require('../components/portfolio/images/illustration/midSize/violinplayer.jpg'),
    slug: 'illustration-violinplayer',
    type: [IllustrationType.FullPageIllustration, IllustrationType.ConceptArt],
    colour: [IllustrationMainColour.Brown, IllustrationMainColour.Purple],
  }, {
    imageUrl: require('../components/portfolio/images/comics/violins-1.jpg'),
    imageThumbnail: require('../components/portfolio/images/comics/thumbnails/violins-1.jpg'),
    imageMidSize: require('../components/portfolio/images/comics/midSize/violins-1.jpg'),
    slug: 'comics-violins-1',
    type: [IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Brown, IllustrationMainColour.Purple],
  }, {
    imageUrl: require('../components/portfolio/images/comics/violins-2.jpg'),
    imageThumbnail: require('../components/portfolio/images/comics/thumbnails/violins-2.jpg'),
    imageMidSize: require('../components/portfolio/images/comics/midSize/violins-2.jpg'),
    slug: 'comics-violins-2',
    type: [IllustrationType.Editorial, IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Brown, IllustrationMainColour.Purple],
  }, {
    imageUrl: require('../components/portfolio/images/comics/violins-3.jpg'),
    imageThumbnail: require('../components/portfolio/images/comics/thumbnails/violins-3.jpg'),
    imageMidSize: require('../components/portfolio/images/comics/midSize/violins-3.jpg'),
    slug: 'comics-violins-3',
    type: [IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Brown, IllustrationMainColour.Purple],
  }, {
    imageUrl: require('../components/portfolio/images/comics/violins-4.jpg'),
    imageThumbnail: require('../components/portfolio/images/comics/thumbnails/violins-4.jpg'),
    imageMidSize: require('../components/portfolio/images/comics/midSize/violins-4.jpg'),
    slug: 'comics-violins-4',
    type: [IllustrationType.ComicBookPages],
    colour: [IllustrationMainColour.Brown, IllustrationMainColour.Purple],
  }]
}, {
  slug: 'stickers',
  title: 'Assorted Stickers',
  date: '2021 - 2022',
  description: [
    "Stickers created to sell at fairs and conventions, and in my online shop. Just a bit of fun, really.",
    "Illustration and story by Claudia Matosa.",
    "Tools: Clip Studio Paint and a Silhouette Cameo."
  ],
  externalLinks: [
    { link: 'https://shop.claudiamatosa.com/products/worlds-apart-mini-55980', title: 'Order online' },
  ],
  images: [{
    imageUrl: require('../components/portfolio/images/printed/stickers-1.jpg'),
    imageThumbnail: require('../components/portfolio/images/printed/thumbnails/stickers-1.jpg'),
    imageMidSize: require('../components/portfolio/images/printed/midSize/stickers-1.jpg'),
    slug: 'stickers-1',
    type: [IllustrationType.Others],
    colour: [IllustrationMainColour.Green],
  }, {
    imageUrl: require('../components/portfolio/images/printed/stickers-2.jpg'),
    imageThumbnail: require('../components/portfolio/images/printed/thumbnails/stickers-2.jpg'),
    imageMidSize: require('../components/portfolio/images/printed/midSize/stickers-2.jpg'),
    slug: 'stickers-2',
    type: [IllustrationType.Others],
    colour: [IllustrationMainColour.Orange, IllustrationMainColour.Brown],
  }, {
    imageUrl: require('../components/portfolio/images/printed/stickers-3.jpg'),
    imageThumbnail: require('../components/portfolio/images/printed/thumbnails/stickers-3.jpg'),
    imageMidSize: require('../components/portfolio/images/printed/midSize/stickers-3.jpg'),
    slug: 'stickers-3',
    type: [IllustrationType.Others],
    colour: [IllustrationMainColour.Pink],
  }, {
    imageUrl: require('../components/portfolio/images/printed/stickers-4.jpg'),
    imageThumbnail: require('../components/portfolio/images/printed/thumbnails/stickers-4.jpg'),
    imageMidSize: require('../components/portfolio/images/printed/midSize/stickers-4.jpg'),
    slug: 'stickers-4',
    type: [IllustrationType.Others],
    colour: [IllustrationMainColour.Orange, IllustrationMainColour.Blue],
  }, {
    imageUrl: require('../components/portfolio/images/printed/stickers-5.jpg'),
    imageThumbnail: require('../components/portfolio/images/printed/thumbnails/stickers-5.jpg'),
    imageMidSize: require('../components/portfolio/images/printed/midSize/stickers-5.jpg'),
    slug: 'stickers-5',
    type: [IllustrationType.Others],
    colour: [IllustrationMainColour.Purple],
  }, {
    imageUrl: require('../components/portfolio/images/printed/stickers-7.jpg'),
    imageThumbnail: require('../components/portfolio/images/printed/thumbnails/stickers-7.jpg'),
    imageMidSize: require('../components/portfolio/images/printed/midSize/stickers-7.jpg'),
    slug: 'stickers-7',
    type: [IllustrationType.Others],
    colour: [IllustrationMainColour.Brown],
  }, {
    imageUrl: require('../components/portfolio/images/printed/stickers-8.jpg'),
    imageThumbnail: require('../components/portfolio/images/printed/thumbnails/stickers-8.jpg'),
    imageMidSize: require('../components/portfolio/images/printed/midSize/stickers-8.jpg'),
    slug: 'stickers-8',
    type: [IllustrationType.Others],
    colour: [IllustrationMainColour.Orange, IllustrationMainColour.Brown],
  }, {
    imageUrl: require('../components/portfolio/images/printed/stickers-9.jpg'),
    imageThumbnail: require('../components/portfolio/images/printed/thumbnails/stickers-9.jpg'),
    imageMidSize: require('../components/portfolio/images/printed/midSize/stickers-9.jpg'),
    slug: 'stickers-9',
    type: [IllustrationType.Others],
    colour: [IllustrationMainColour.Orange, IllustrationMainColour.White],
  }, {
    imageUrl: require('../components/portfolio/images/printed/stickers-10.jpg'),
    imageThumbnail: require('../components/portfolio/images/printed/thumbnails/stickers-10.jpg'),
    imageMidSize: require('../components/portfolio/images/printed/midSize/stickers-10.jpg'),
    slug: 'stickers-10',
    type: [IllustrationType.Others],
    colour: [IllustrationMainColour.Orange, IllustrationMainColour.Brown],
  }]
}, 
{
  slug: 'talltales-shortstories',
  title: 'Tall Tales & Short Stories - Book Covers',
  date: '2024',
  description: [
    "The brief was to illustrate the cover for two comic books to be sold together via Kickstarter. Each book contains comics from multiple artists, under two themes: Tall Tales and Short Stories. The client was looking for a design that encouraged customers to order both books. In response to that, we worked on a design that spawns across both books. To have the complete design, customers need to order both.",
    "Illustration by Claudia Matosa and lettering by Mike Armstrong.",
    "Tools: Clip Studio Paint and Photoshop"
  ],
  externalLinks: [
    { link: 'https://www.kickstarter.com/projects/joestone/tall-tales-and-short-stories-new-wip-comics-anthologies', title: 'Kickstarter Campaign' },
  ],
  images: [{
    imageUrl: require('../components/portfolio/images/covers/talltales-1.jpg'),
    imageThumbnail: require('../components/portfolio/images/covers/thumbnails/talltales-1.jpg'),
    imageMidSize: require('../components/portfolio/images/covers/midSize/talltales-1.jpg'),
    slug: 'covers-talltales-1',
    type: [IllustrationType.BookCoverDesign],
    colour: [IllustrationMainColour.Red],
    description: "Illustration by Claudia Matosa | Lettering by Mike Armstrong (mike-armstrong.com)"
  }, {
    imageUrl: require('../components/portfolio/images/covers/shortstories-1.jpg'),
    imageThumbnail: require('../components/portfolio/images/covers/thumbnails/shortstories-1.jpg'),
    imageMidSize: require('../components/portfolio/images/covers/midSize/shortstories-1.jpg'),
    slug: 'covers-shortstories-1',
    type: [IllustrationType.BookCoverDesign],
    colour: [IllustrationMainColour.Green],
    spanColumns: 2,
    description: "Illustration by Claudia Matosa | Lettering by Mike Armstrong (mike-armstrong.com)"
  }, {
    imageUrl: require('../components/portfolio/images/covers/wip-covers.jpg'),
    imageThumbnail: require('../components/portfolio/images/covers/thumbnails/wip-covers.jpg'),
    imageMidSize: require('../components/portfolio/images/covers/midSize/wip-covers.jpg'),
    slug: 'covers-wip',
    type: [IllustrationType.BookCoverDesign],
    colour: [IllustrationMainColour.Green],
    description: "Illustration by Claudia Matosa | Lettering by Mike Armstrong (mike-armstrong.com)"
  }],
}];

export const defaultPortfolioImages: PortfolioImageKeys = [
  ['thelastdayofrain', 'illustration-lastdayofrain-1'],
  ['thelastdayofrain', 'illustration-lastdayofrain-2'],
  ['phobos', 'illustration-phobos-1'],
  ['phobos', 'illustration-phobos-2'],
  ['thelastdayofrain', 'illustration-lastdayofrain-4'],
  ['thelastdayofrain', 'illustration-lastdayofrain-5'],
  ['thelastdayofrain', 'illustration-lastdayofrain-6'],
  ['thelastdayofrain', 'printed-ldor-9'],
  ['theseawallofplymouth', 'illustration-seawallofplymouth-1'],
  ['theseawallofplymouth', 'illustration-seawallofplymouth-2'],
  ['theseawallofplymouth', 'illustration-seawallofplymouth-3'],
  ['theseawallofplymouth', 'printed-plymouth-2'],
  ['thelastdayofrain', 'illustration-lastdayofrain-7'],
  ['violins', 'illustration-violinplayer'],
  ['shibatown', 'illustration-lastdayofrain-3'],
  // ['theseawallofplymouth', 'illustration-seawallofplymouth-4'],
  ['thelastdayofrain', 'characters-ldor-3'],
  ['phobos', 'illustration-phobos-3'],
  ['phobos', 'illustration-phobos-4'],
  ['theseawallofplymouth', 'comics-plymouth-3'],
  ['worldsapart', 'snippets-ldor-2'],
  ['phobos', 'snippets-phobos-1'],
  ['worldsapart', 'printed-worldsapart-1'],
  ['theseawallofplymouth', 'snippets-plymouth-1'],
  ['phobos', 'printed-phobos-2'],
  ['phobos', 'snippets-phobos-5'],
  ['phobos', 'snippets-phobos-3'],
  ['thelastdayofrain', 'characters-ldor-1'],
  ['thelastdayofrain', 'characters-ldor-2'], // blurry
  ['stickers', 'stickers-5'],
  // ['theseawallofplymouth', 'printed-plymouth-1'],
  // ['thelastdayofrain', 'printed-ldor-1'],
  // ['thelastdayofrain', 'printed-ldor-4'],
  // ['thelastdayofrain', 'printed-ldor-7'],
  // ['phobos', 'printed-phobos-1'],
  // ['theverybigsecret', 'covers-verybigsecret-1'],
  ['thelastdayofrain', 'covers-ldor-5'],
  ['thelastdayofrain', 'covers-ldor-7'],
  // ['thelastdayofrain', 'covers-ldor-3'],
  ['thelastdayofrain', 'comics-ldor-21'],
  ['thelastdayofrain', 'comics-ldor-1'],
  ['thelastdayofrain', 'comics-ldor-2'],
  ['thelastdayofrain', 'comics-ldor-4'],
  ['thelastdayofrain', 'comics-ldor-5'],
  ['thelastdayofrain', 'comics-ldor-7'],
  ['thelastdayofrain', 'comics-ldor-9'],
  ['thelastdayofrain', 'comics-ldor-11'],
  ['thelastdayofrain', 'comics-ldor-12'],
  ['thelastdayofrain', 'comics-ldor-20'],
  ['thelastdayofrain', 'comics-ldor-8'],
  // ['thelastdayofrain', 'comics-ldor-15'],
  ['thelastdayofrain', 'comics-ldor-17'],
  // ['thelastdayofrain', 'comics-ldor-18'],
  ['thelastdayofrain', 'comics-ldor-19'],
  ['theseawallofplymouth', 'comics-plymouth-1'],
  ['theseawallofplymouth', 'comics-plymouth-2'],
  // ['violins', 'comics-violins-2'],
];

export const sections = [
  {
    slug: 'book-covers',
    name: 'Book Covers',
    illustrationTypes: [IllustrationType.BookCoverDesign],
    thumbnail: require('../images/portfolio-covers/bookcovers-cover.jpg'),
    items: [
      // ['thelastdayofrain', 'covers-ldor-1'],
      // ['thelastdayofrain', 'covers-ldor-2'],
      // ['thelastdayofrain', 'covers-ldor-4'],
      // ['thelastdayofrain', 'covers-ldor-6'],
      // ['thelastdayofrain', 'covers-ldor-8'],
      ['phobos', 'cover-and-back-phobos'],
      ['theseawallofplymouth', 'cover-and-back-plymouth'],
      ['thelastdayofrain', 'cover-and-back-ldor'],
      ['talltales-shortstories', 'covers-wip'],
      // ['thelastdayofrain', 'cover-and-back-ldor-2'],
      ['phobos', 'covers-phobos-1'],
      ['theseawallofplymouth', 'covers-plymouth-1'],
      ['thelastdayofrain', 'covers-ldor-3'],
      ['talltales-shortstories', 'covers-talltales-1'],
      ['talltales-shortstories', 'covers-shortstories-1'],
      ['thelastdayofrain', 'covers-ldor-7'],
      ['thelastdayofrain', 'covers-ldor-5'],
    ]
  },
  {
    slug: 'illustration',
    name: 'Illustration',
    illustrationTypes: [IllustrationType.FullPageIllustration, IllustrationType.Editorial, IllustrationType.Others, IllustrationType.ConceptArt, IllustrationType.CharacterDesign],
    thumbnail: require('../images/portfolio-covers/illustration-cover.jpg'),
    items: [
      ['thelastdayofrain', 'illustration-lastdayofrain-1'],
      ['thelastdayofrain', 'illustration-lastdayofrain-2'],
      ['phobos', 'illustration-phobos-1'],
      ['phobos', 'illustration-phobos-2'],
      ['thelastdayofrain', 'illustration-lastdayofrain-6'],
      ['theseawallofplymouth', 'illustration-seawallofplymouth-3'],
      ['theseawallofplymouth', 'illustration-seawallofplymouth-2'],
      ['theseawallofplymouth', 'illustration-seawallofplymouth-1'],
      ['thelastdayofrain', 'illustration-lastdayofrain-4'],
      ['thelastdayofrain', 'illustration-lastdayofrain-5'],
      ['shibatown', 'illustration-lastdayofrain-3'],
      ['theseawallofplymouth', 'comics-plymouth-3'],
      ['thelastdayofrain', 'characters-ldor-1'],
      ['thelastdayofrain', 'characters-ldor-3'],
    ],
  }, {
    slug: 'comic-book-pages',
    name: 'Comic Book Art',
    illustrationTypes: [IllustrationType.ComicBookPages],
    thumbnail: require('../images/portfolio-covers/comics-cover.jpg'),
    items: [
      ['thelastdayofrain', 'comics-ldor-11'],
      ['thelastdayofrain', 'comics-ldor-5'],
      ['thelastdayofrain', 'comics-ldor-7'],
      ['thelastdayofrain', 'comics-ldor-20'],
      ['thelastdayofrain', 'comics-ldor-12'],
      ['thelastdayofrain', 'comics-ldor-1'],
      ['thelastdayofrain', 'comics-ldor-2'],
      ['thelastdayofrain', 'comics-ldor-4'],
      ['theseawallofplymouth', 'comics-plymouth-1'],
      ['theseawallofplymouth', 'comics-plymouth-2'],
      ['theseawallofplymouth', 'comics-plymouth-3'],
      ['thelastdayofrain', 'comics-ldor-8'],
      ['thelastdayofrain', 'comics-ldor-17'],
      ['thelastdayofrain', 'comics-ldor-19'],
    ],
  }
];

export const getProject = (slug: string) => projects.find(project => project.slug === slug);

export const getImageForProject = (projectSlug: string, imageSlug: string) => {
  const project = getProject(projectSlug);
  return project?.images.find(image => image.slug === imageSlug);
}
