/*
  Filters by type of illustration: book cover design, concept art, sketches, comics, others, all, project name
*/

import styled from 'styled-components';
import { NavLink, useParams } from 'react-router-dom';
import { PortfolioImageKeys, sections } from '../../data/portfolio';
import { PortfolioGrid } from './PortfolioGrid';
import { fromMedium } from '../../styles/mediaQueries';
import { light, primary } from '../../styles/colors';

interface RouteParams {
  section: string
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const Filters = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1em;
  list-style: none;
  padding: 0 1em;
  margin-top: 1.5em;
  flex-wrap: wrap;
  max-width: 100%;
  font-size: 1.1em;

  li {
    margin: 0;
    padding: 0;
  }

  a, a:visited {
    color: ${primary};
    opacity: 0.8;
    transition: opacity 0.3s ease-in-out;
    transition-property: opacity, color;
  }

  a:hover, a:focus {
    opacity: 1;
  }

  a.active {
    padding: 0 0.5em;
    background-color: ${primary};
    color: ${light};
    border-radius: 0.2em;
  }

  ${fromMedium} {
    margin-top: 0;
  }
`;

function getSectionFilters(section: string) {
  return sections.find(filter => filter.slug === section);
}

function getImagesForSection(section: string): PortfolioImageKeys {
  const sectionFilter = getSectionFilters(section);
  return sectionFilter?.items as PortfolioImageKeys;
}

export function PortfolioSection() {
  const routeParams = useParams<RouteParams>();
  const imagesForSection = routeParams.section && routeParams.section !== 'all' ? getImagesForSection(routeParams.section) : undefined;;

  return (
    <Container>
      <Filters>
        {sections.map(({slug, name}) => (
          <li>
            <NavLink to={`/portfolio/section/${slug}`}>
              {name}
            </NavLink>
          </li>
        ))}
      </Filters>

      {imagesForSection && <PortfolioGrid images={imagesForSection} />}
    </Container>
  )
}
